<template>
  <div>
    <no-ssr>
      <b-form-group>
        <b-form-checkbox-group
          :name="`checkbox_${attrCode}`"
          v-model="selected"
          :options="options"
          @change="change"
        />
      </b-form-group>
    </no-ssr>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    attrCode: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      options (state) {
        if (this.attrCode && state.listing.filters.available[this.attrCode]) {
          return state.listing.filters.available[this.attrCode].options.map(opt => ({
            value: opt.value,
            text: opt.label + ` ( ${opt.count} )`
          }))
        }
        return []
      }
    }),
    selected: {
      get () {
        return this.attrCode && this.$store.state.listing.filters.chosen[this.attrCode]
          ? this.$store.state.listing.filters.chosen[this.attrCode]
          : []
      },
      set (newValues) {
        this.$store.commit('listing/updateFilters', this.getFilterCompose(newValues))
        return newValues
      }
    }
  },
  methods: {
    change (newValues) {
      this.$emit('changeFilter', this.getFilterCompose(newValues))
    },
    getFilterCompose (newValues) {
      return { attrCode: this.attrCode, valueSelected: newValues }
    }
  }
}
</script>
