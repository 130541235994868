<template>
  <div class="text-left">
    <b-btn v-b-toggle="`filters_${attrCode}`" class="my-1 text-left" block variant="light">{{ label }}</b-btn>
    <b-collapse visible :id="`filters_${attrCode}`">
      <component
        :is="typeFilter"
        :attrCode="attrCode"
        @changeFilter="$emit('changeFilter', $event)"/>
    </b-collapse>
  </div>
</template>

<script>
import CheckboxFilter from './FiltersLayout/CheckboxFilter'

export default {
  props: {
    attrCode: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    typeFilter () {
      return 'checkbox-filter'
    }
  },
  components: {
    CheckboxFilter
  }
}
</script>
