<template>
  <div>
    <generic-filter
      v-for="(filter, attrCode) in available"
      :key="attrCode"
      :attrCode="attrCode"
      :label="filter.label"
      @changeFilter="$emit('changeFilter', $event)"
    />
  </div>
</template>

<script>
import GenericFilter from './GenericFilter'
export default {
  props: {
    available: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    GenericFilter
  }
}
</script>
